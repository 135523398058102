import React from 'react'
import AcademyImg from "../assets/img/homepage/Artboard-2.png"
import { Link } from 'react-router-dom'
import Icon3 from "../assets/img/homepage/icons/icon_Basari.svg"
import { useTranslation } from 'react-i18next'

const AboutUs2 = () => {
    const { t } = useTranslation();
    return (
        <div className='about-us-content my-5'>
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-lg-6">
                        <h3 className="component-title my-3">
                            <h3 className="component-title my-3">{t("educationmaterial")}</h3>
                        </h3>
                        <div className="about-us-icons mt-5">
                            <div className='row align-items-center about-us-icon'>
                                <div className="col-lg-2 col-md-2 col-12 mb-lg-0 mb-3">
                                    <div className='icons-content'>
                                        <img width="35" height="35" src="https://img.icons8.com/ios/50/3967AF/diploma.png" alt="bitcoin--v1" />
                                    </div>
                                </div>
                                <div className="col-lg-9 col-md-9 col-10">
                                    <div className="d-flex flex-column">
                                        <h4>{t("academyTitle")}</h4>
                                        <p className='mb-0'>{t("academydesc")}</p>
                                    </div>
                                </div>
                                <div className="col-lg-1 col-md-1 col-2">
                                    <Link to="/academy">
                                        <img width="100%" src="https://img.icons8.com/ios/50/0782DA/long-arrow-right--v1.png" alt="long-arrow-right--v1" />
                                    </Link>
                                </div>
                            </div>
                            <div className='row align-items-center about-us-icon'>
                                <div className="col-lg-2 col-md-2 mb-lg-0 mb-3">
                                    <div className='icons-content'>
                                        <img width="35" height="35" src="https://img.icons8.com/ios/50/3967AF/investment.png" alt="bitcoin--v1" />
                                    </div>
                                </div>
                                <div className="col-lg-9 col-md-9 col-10">
                                    <div className="d-flex flex-column">
                                        <h4>{t("investmentProductsTitle")}</h4>
                                        <p className='mb-0'>{t("investmentProductsContent")}</p>
                                    </div>
                                </div>
                                <div className="col-lg-1 col-md-1 col-2">
                                    <Link to="/investment-products">
                                        <img width="100%" src="https://img.icons8.com/ios/50/0782DA/long-arrow-right--v1.png" alt="long-arrow-right--v1" />
                                    </Link>
                                </div>
                            </div>
                            <div className='row align-items-center about-us-icon'>
                                <div className="col-lg-2 col-md-2 mb-lg-0 mb-3">
                                    <div className='icons-content'>
                                        <img width="35" height="35" src={Icon3} alt="bitcoin--v1" />
                                    </div>
                                </div>
                                <div className="col-lg-9 col-md-9">
                                    <div className="d-flex flex-column">
                                        <h4>{t("successTitle")}</h4>
                                        <p className='mb-0'>{t("successContent")}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6 text-end mt-lg-0 mt-4 about-us2-content-img">
                        <img src={AcademyImg} className='about-us-image' alt="banner-image" />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AboutUs2