import React from 'react'
import Card1Img from "../assets/img/investment-products/PARiETELER.png"
import Card2Img from "../assets/img/investment-products/EMTiALAR.png"
import Card3Img from "../assets/img/investment-products/ENDEKSLER.png"
import Card4Img from "../assets/img/investment-products/CFD-urunler.png"
import Card5Img from "../assets/img/investment-products/HiSSELER.png"
import Card6Img from "../assets/img/investment-products/KRiPTO-PARALAR.png"
import { useTranslation } from 'react-i18next'

const InvestmentProductsComponent = () => {
    const { t } = useTranslation();
    const dataList = [
        {
            img: Card1Img,
            title: t("parite"),
            text: t("paritedesc")
        },
        {
            img: Card2Img,
            title: t("emtia"),
            text: t("emtiadesc")
        },
        {
            img: Card3Img,
            title: t("endeks"),
            text: t("endeksdesc")
        },
        {
            img: Card4Img,
            title: t("cfd"),
            text: t("cfddesc")
        },
        {
            img: Card5Img,
            title: t("hisse"),
            text: t("hissedesc")
        },
        {
            img: Card6Img,
            title: t("kripto"),
            text: t("kriptodesc")
        },
    ]
    return (
        <div className='trading-platforms-component-content'>
            {/* <h5 className="component-subtitle">Silver Invest'in Yatırım Ürünleri</h5> */}
            <h3 className="component-title">{t("investtitle")} <br /> {t("investtitle2")}</h3>
            <p className="component-paragraph mt-3">
                {t("investdesc")}
            </p>
            <div className="row gy-4 mt-5">
                {
                    dataList.map((e, index) => (
                        <div className="col-lg-6">
                            <div className="investment-card d-flex flex-column justify-content-end shadow" style={{ backgroundImage: `url(${e.img})` }}>
                                <h5 className='mb-3'>{e.title}</h5>
                                <p className="mb-0">
                                    {e.text}
                                </p>
                            </div>
                        </div>
                    ))
                }
            </div>
        </div>
    )
}

export default InvestmentProductsComponent