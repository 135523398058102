import React from 'react'
import SubpageBanner from '../components/SubpageBanner'
import InvestmentProductsComponent from '../components/InvestmentProductsComponent'
import Platforms from '../components/Platforms'
import TextCta from '../components/TextCta'
import TradingPlatformsBanner from "../assets/img/trading-platforms/b-islem-platformu.png"
import { useTranslation } from 'react-i18next'

const InvestmentProducts = () => {
    const { t } = useTranslation();
    return (
        <div className='trading-platforms-content'>
            <SubpageBanner title={t("investmentProductsTitle")} image={TradingPlatformsBanner} />
            <div className="container">
                <InvestmentProductsComponent />
            </div>
            <TextCta title={t("investmentprodctatext")} />
        </div>
    )
}

export default InvestmentProducts