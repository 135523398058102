import React from 'react'
import SubpageBanner from '../components/SubpageBanner'
import PromotionsImg from "../assets/img/promotions/b-PROMOSYON.png"
import PromotionsComponent from '../components/PromotionsComponent'
import { useTranslation } from 'react-i18next'

const Promotions = () => {
    const { t } = useTranslation();
    return (
        <div className='promotions-content'>
            <SubpageBanner title={t("promotionsTitle")} image={PromotionsImg} />
            <div className="container">
                <PromotionsComponent />
            </div>
        </div>
    )
}

export default Promotions