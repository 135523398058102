import React from 'react'
import { useTranslation } from 'react-i18next'

const ContactCards = () => {
    const { t } = useTranslation();
    return (
        <div className='contact-card-content'>
            <div className="container">
                <div className="row">
                    <div className="col-lg-4">
                        <div className="contact-card h-100">
                            <div className="mt-4">
                                <h3 className='mb-4'>{t("location")}</h3>
                                <a href='https://maps.app.goo.gl/TAB4cXhWHPNtjKUe8' target='_blank' className='text-decoration-none text-dark'>
                                    {t("locationaddress")}
                                </a>
                            </div>
                            <div className="contact-card-icon">
                                <img width="40" height="40" src="https://img.icons8.com/ios-filled/40/ffffff/marker.png" alt="marker" />
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4">
                        <div className="contact-card h-100">
                            <div className="mt-4">
                                <h3 className='mb-4'>{t("email")}</h3>
                                <a href="mailto:support@silverinvestmentsgroup.com" className='text-decoration-none text-dark'>
                                    support@silverinvestmentsgroup.com
                                </a>
                            </div>
                            <div className="contact-card-icon">
                                <img width="40" height="40" src="https://img.icons8.com/ios-filled/40/ffffff/new-post.png" alt="new-post" />
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4">
                        <div className="contact-card h-100">
                            <div className="mt-4">
                                <h3 className='mb-4'>{t("phone")}</h3>
                                <a href="https://wa.me/447585748974" target='_blank' className='text-decoration-none text-dark'>
                                    +44 7585 748 974
                                </a>
                            </div>
                            <div className="contact-card-icon">
                                <img width="40" height="40" src="https://img.icons8.com/ios-filled/40/ffffff/phone.png" alt="phone" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ContactCards