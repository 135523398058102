import React, { useState, useEffect } from "react";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import MaskedInput from "react-text-mask";
import InputMask from 'react-input-mask';
import axios from "axios";
import { useTranslation } from "react-i18next";
import { toast, ToastContainer } from "react-toastify";

const ContactForm = () => {
    const { t } = useTranslation();

    const LoginSchema = Yup.object().shape({
        firstName: Yup.string().required(t("entername")),
        lastName: Yup.string().required(t("entersurname")),
        email: Yup.string()
            .email(t("entervalidmail"))
            .required(t("enteremail")),
        phone: Yup.string()
            .required(t("enterphone"))
            .test(
                "starts-with-5",
                t("entervalidphone"),
                (value) => value && value[1] === "5"
            ),
    });

    let brandType = 0;
    var formUrl = window.location.protocol + '//' + window.location.hostname + "/api/v1/Submit?brand=" + brandType;
    // let formUrl = `https://silver.testmedici.com/api/v1/Submit?brand=${brandType}`

    const phoneNumberMask = [
        "(",
        /[1-9]/,
        /\d/,
        /\d/,
        ")",
        " ",
        /\d/,
        /\d/,
        /\d/,
        " ",
        /\d/,
        /\d/,
        " ",
        /\d/,
        /\d/,
    ];

    const onSubmit = async (values, { setSubmitting, resetForm }) => {
        try {
            const formattedPhone = values.phone.replace(/[()\s]/g, "");
            const formData = {
                ...values,
                phone: formattedPhone,
            };
            const response = await axios.post(formUrl, formData);
            toast.success(t("registersuccess"));
            resetForm();
        } catch (error) {
            if (!error.response.data.success) {
                if (!error.response.data.message.includes("DUPLICATE_DATA") || !error.response.data.message.includes("User already exist!!!")) {
                    toast.error(t("registeruseralreadyexist"));
                } else {
                    toast.error(t("registerfailed"));
                }
            }
        } finally {
            setSubmitting(false);
        }
    };

    return (
        <div className='homepage-contact-container'>
            <div className="container">
                <div className="text-center">
                    <h5 className="component-subtitle">{t("createaccount")}</h5>
                    <h3 className="component-title my-3">{t("homePageSubtitle2")}</h3>
                </div>
                <div className="row justify-content-center mt-5">
                    <div className="col-lg-6">
                        <Formik
                            initialValues={{
                                firstName: "",
                                lastName: "",
                                email: "",
                                phone: "",
                            }}
                            validationSchema={LoginSchema}
                            onSubmit={onSubmit}
                        >
                            {({ touched, errors, isSubmitting, setFieldValue }) => (
                                <Form className="row">
                                    <div className="col-md-12">
                                        <Field
                                            type="text"
                                            name="firstName"
                                            placeholder={t("name")}
                                            className={`form-control ${touched.firstName && errors.firstName ? "is-invalid" : ""
                                                }`}
                                        />
                                        <ErrorMessage
                                            component="div"
                                            name="firstName"
                                            className="invalid-feedback text-start"
                                        />
                                    </div>
                                    <div className="col-md-12 mt-3">
                                        <Field
                                            type="text"
                                            name="lastName"
                                            placeholder={t("surname")}
                                            className={`form-control ${touched.lastName && errors.lastName ? "is-invalid" : ""}`}
                                        />
                                        <ErrorMessage
                                            component="div"
                                            name="lastName"
                                            className="invalid-feedback text-start"
                                        />
                                    </div>
                                    <div className="col-md-12 mt-3">
                                        <Field
                                            type="email"
                                            name="email"
                                            placeholder={t("email")}
                                            className={`form-control ${touched.email && errors.email ? "is-invalid" : ""}`}
                                        />
                                        <ErrorMessage
                                            component="div"
                                            name="email"
                                            className="invalid-feedback text-start"
                                        />
                                    </div>
                                    <div className="col-md-12 mt-3">
                                        <Field
                                            name="phone"
                                            render={({ field }) => (
                                                <MaskedInput
                                                    {...field}
                                                    mask={phoneNumberMask}
                                                    placeholder={t("phone")}
                                                    id="phone"
                                                    type="text"
                                                    className={`form-control ${touched.phone && errors.phone ? "is-invalid" : ""}`}
                                                />
                                            )}
                                        />
                                        <ErrorMessage
                                            component="div"
                                            name="phone"
                                            className="invalid-feedback text-start"
                                        />
                                    </div>
                                    <div className="text-center mt-5">
                                        <button
                                            type="submit"
                                            className="btn-secondary-v2 w-100"
                                            disabled={isSubmitting}
                                        >
                                            {isSubmitting ? t("sending") : t("send")}
                                        </button>
                                    </div>
                                </Form>
                            )}
                        </Formik>
                    </div>
                </div>
            </div>
            <ToastContainer />
        </div>
    )
}

export default ContactForm;
