import React, { useEffect, useState } from 'react';
import Logo from "../assets/img/silver/svg/horizontal-logo-color-cropped.svg";
import Ios from "../assets/img/ios.png";
import Android from "../assets/img/android.png";
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const MobileHeader = () => {
    const { t } = useTranslation();

    const [scaurl, setScaurl] = useState("sca." + window.location.host.replace("www.", ""));

    return (
        <div className='header-content'>
            <div className="container py-3">
                <div className="d-flex align-items-center justify-content-between">
                    <div className="header-logo">
                        <Link to="/">
                            <img src={Logo} alt="logo" width="110px" />
                        </Link>
                    </div>
                    <button className="btn-secondary-v2" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasExample">
                        <img width="24" height="24" src="https://img.icons8.com/ios-filled/24/ffffff/menu--v6.png" alt="menu--v6" />
                    </button>
                    <div className="offcanvas offcanvas-start" tabIndex={-1} id="offcanvasExample" data-bs-backdrop="false">
                        <div className="offcanvas-header">
                            <div className="header-logo">
                                <Link to="/">
                                    <img src={Logo} alt="logo" width="110px" />
                                </Link>
                            </div>
                            <button type="button" className="btn-close" data-bs-dismiss="offcanvas" aria-label="Close" />
                        </div>
                        <div className="offcanvas-body mt-4">
                            <div className='text-center mobile-navigation'>
                                <Link to="/" className="text-dark text-decoration-none">
                                    <div className='mb-3'>{t("homepage")}</div>
                                </Link>
                                <Link to="/academy" className="text-dark text-decoration-none">
                                    <div className='mb-3'>{t("academy")}</div>
                                </Link>
                                <Link to="/about" className="text-dark text-decoration-none">
                                    <div className='mb-3'>{t("about")}</div>
                                </Link>
                                <Link to="/trading-platforms" className="text-dark text-decoration-none">
                                    <div className='mb-3'>{t("tradingplatforms")}</div>
                                </Link>
                                <Link to="/faq" className="text-dark text-decoration-none">
                                    <div className='mb-3'>{t("faq")}</div>
                                </Link>
                                <Link to="/investment-products" className="text-dark text-decoration-none">
                                    <div className='mb-3'>{t("investmentproducts")}</div>
                                </Link>
                                <div className='mt-4'>
                                    <Link to="/register">
                                        <button className='btn btn-primary-v2 w-100'>{t("createaccount")}</button>
                                    </Link>
                                </div>
                                <div className='mt-2'>
                                    <a href={`https://${scaurl}`} target='_blank'>
                                        <button className='btn btn-secondary-v2 w-100'>{t("login")}</button>
                                    </a>
                                </div>
                            </div>
                            {/* <div className='d-flex flex-wrap align-items-center mt-5 platform-app-icons'>
                                <button className="mobile-app d-flex align-items-center justify-content-between me-lg-4 me-md-0 me-0 mb-lg-0 mb-md-0 mb-3">
                                    <img src={Android} alt="android" className='me-3' />
                                    <div className="d-flex flex-column align-items-start">
                                        <div className='mobile-app-subtitle'>Get it on</div>
                                        <div className='mobile-app-title'>Google Play</div>
                                    </div>
                                </button>
                                <button className="mobile-app d-flex align-items-center justify-content-between">
                                    <img src={Ios} alt="ios" className='me-3' />
                                    <div className="d-flex flex-column align-items-start">
                                        <div className='mobile-app-subtitle'>Get it on</div>
                                        <div className='mobile-app-title'>Apple Store</div>
                                    </div>
                                </button>
                            </div> */}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default MobileHeader;
