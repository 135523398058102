import React from 'react'
import SubpageBanner from '../components/SubpageBanner'
import ContactForm from '../components/ContactForm'
import ContactCards from '../components/ContactCards'
import RegisterImg from "../assets/img/register/b-Kayit-ol.png"
import { useTranslation } from 'react-i18next'

const Register = () => {
    const { t } = useTranslation();
    return (
        <div className='contact-content'>
            <SubpageBanner title={t("createaccount")} image={RegisterImg} />
            <ContactForm />
            <ContactCards />
            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2483.346330751206!2d-0.14127752349715889!3d51.506861871813165!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x487604d7ace36efb%3A0xe10a743604455f73!2s14%20Bury%20St%2C%20London%20SW1A%201HA%2C%20Birle%C5%9Fik%20Krall%C4%B1k!5e0!3m2!1str!2sbg!4v1727183636552!5m2!1str!2sbg" width="100%" height={500} style={{ border: 0 }} allowFullScreen loading="lazy" referrerPolicy="no-referrer-when-downgrade" />
        </div>
    )
}

export default Register