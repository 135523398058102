import React from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import Img1 from "../assets/img/promotions/hos-geldin-bonusu.png"
import Img2 from "../assets/img/promotions/kidemli-yatirimci.png"
import Img3 from "../assets/img/promotions/arkadasini-getir.png"
import Img4 from "../assets/img/promotions/vip-programi.png"
import Img5 from "../assets/img/promotions/egitim-programi.png"
import Img6 from "../assets/img/promotions/sabit-kur.png"

const PromotionsComponent = () => {
    const { t } = useTranslation();
    const dataList = [
        {
            image: Img1,
            title: t("promotion1title"),
            text1: t("promotion1text"),
            text2: t("promotion1text2")
        },
        {
            image: Img2,
            title: t("promotion2title"),
            text1: t("promotion2text"),
            text2: t("promotion2text2")
        },
        {
            image: Img3,
            title: t("promotion3title"),
            text1: t("promotion3text"),
            text2: t("promotion3text2")
        },
        {
            image: Img4,
            title: t("promotion4title"),
            text1: t("promotion4text"),
            text2: t("promotion4text2")
        },
        {
            image: Img5,
            title: t("promotion5title"),
            text1: t("promotion5text"),
            text2: t("promotion5text2")
        },
        {
            image: Img6,
            title: t("promotion6title"),
            text1: t("promotion6text"),
            text2: t("promotion6text2"),
        },
    ]
    return (
        <div className='trading-platforms-component-content'>
            <h3 className="component-title">{t("promotionsTitle")}</h3>
            <p className="component-paragraph mt-3">
                {t("promotionsContent")}
            </p>
            <div className="row mt-5">
                <div className="col-lg-12">
                    {
                        dataList.map((e) => (
                            <div className="promotions-card mt-4 shadow-sm">
                                <div className="row align-items-center">
                                    <div className="col-lg-3">
                                        <div className='promotions-card-image' style={{ backgroundImage: `url(${e.image})` }}></div>
                                    </div>
                                    <div className="col-lg-8 p-lg-3 p-4">
                                        <h3 className='mb-4'>{e.title}</h3>
                                        <p className='mb-3'>{e.text1}</p>
                                        {
                                            e.text2 && <p className='mb-0'>{e.text2}</p>
                                        }
                                    </div>
                                </div>
                            </div>
                        ))
                    }
                </div>
            </div>
        </div>
    )
}

export default PromotionsComponent