import React, { useState, useEffect } from 'react'
import Select from "react-select";
import { LANGUAGES } from "../constants/index"
import { useTranslation } from "react-i18next";

const AnnouncementBar = () => {
    const [selectedLanguage, setSelectedLanguage] = useState(
        JSON.parse(localStorage.getItem("lang"))
            ? JSON.parse(localStorage.getItem("lang"))
            : "en"
    );

    const { t, i18n } = useTranslation();

    const onChangeLang = (e) => {
        const lang_code = e.code;
        i18n.changeLanguage(lang_code);
        setSelectedLanguage(e.code);
    };

    const customStyles = {
        control: (provided, state) => ({
            ...provided,
            background: "none",
            border: "none",
            color: "#fff",
            padding: "7px 5px"
        }),
        menu: (provided, state) => ({
            ...provided,
            background: "none",
            color: "#fff",
            width: "150px",
            zIndex: "11111111",
            fontSize: 14
        }),
        option: (provided, state) => ({
            ...provided,
            background: state.isFocused ? "#f0f0f0" : "white",
            color: "#000",
        }),
        singleValue: (provided, state) => ({
            ...provided,
            color: "#000",
            fontSize: 14
        }),
        indicatorSeparator: (provided, state) => ({
            display: "none",
        }),
        dropdownIndicator: (provided, state) => ({
            display: "none",
        }),
    };

    useEffect(() => {
        localStorage.setItem("lang", JSON.stringify(selectedLanguage));
    }, []);

    useEffect(() => {
        localStorage.setItem("lang", JSON.stringify(selectedLanguage));
    }, [selectedLanguage]);

    return (
        <div className='announcement-bar-content'>
            <div className="container">
                <div className="d-flex align-items-center justify-content-end">
                    <div className='me-4'>
                        <a href="https://wa.me/447585748974" target='_blank' className='text-decoration-none text-dark d-flex align-items-center'>
                            <img width="24" height="24" src="https://img.icons8.com/ios-glyphs/30/25D366/whatsapp.png" alt="whatsapp" />
                            <div className='ms-1' style={{ fontSize: "14px" }}>+44 7585 748 974</div>
                        </a>
                    </div>
                    <Select
                        className="language-select-box"
                        defaultValue={
                            LANGUAGES.find((lang) => lang.code === selectedLanguage)
                                ? LANGUAGES.find((lang) => lang.code === selectedLanguage)
                                : LANGUAGES.find((lang) => lang.code === "en")
                        }
                        onChange={onChangeLang}
                        styles={customStyles}
                        isSearchable={false}
                        options={LANGUAGES}
                        getOptionLabel={(option) => (
                            <div className="d-flex align-items-center">
                                <img
                                    src={option.flag}
                                    alt={option.label}
                                    width="25px"
                                    className="me-2"
                                />
                                {option.label}
                            </div>
                        )}
                    />
                </div>
            </div>
        </div>
    )
}

export default AnnouncementBar