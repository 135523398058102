import React from 'react'
import { useTranslation } from "react-i18next";
import AboutImg from "../assets/img/about/Hakkimizda-500x600.png"

const AboutUsDetail = () => {
    const { t } = useTranslation();
    return (
        <div className='about-us-content my-5'>
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-lg-6 position-relative">
                        <img src={AboutImg} className='about-us-image' alt="banner-image" />
                        {/* <div className="experience-box">
                            <h2 className='mb-1'>8</h2>
                            <h4 className='text-center'>{t("yearsexperience")}</h4>
                        </div> */}
                    </div>
                    <div className="col-lg-6">
                        <h5 className="component-subtitle">{t("about")}</h5>
                        <h3 className="component-title my-3">{t("guidanceTitle")}</h3>
                        <p className="component-paragraph mt-4">
                            {t("discoverSilverInvestContent")}
                        </p>
                        <p className="component-paragraph mt-3">
                            {t("securityContent")}
                        </p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AboutUsDetail