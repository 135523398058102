import React, { useState } from 'react';
import DictImg from "../assets/img/academy/sozluk.png";
import EBookImg from "../assets/img/academy/E-KİTAP.png";
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const AcademyComponent = () => {
    const { t, i18n } = useTranslation();
    const [selectedLetter, setSelectedLetter] = useState(null);

    const dictionary = {
        tr: {
            A: [
                { word: t('openposition'), description: t('openpositiontext') },
                { word: t('arbitrage'), description: t('arbitragetext') },
                { word: t('bear'), description: t('beartext') },
                { word: t('broker'), description: t('brokertext') },
            ],
            B: [
                { word: t('bull'), description: t('bulltext') },
                { word: t('exchange'), description: t('exchangetext') },
            ],
            D: [
                { word: t('deflation'), description: t('deflationtext') },
            ],
            E: [
                { word: t('inflation'), description: t('inflationtext') },
            ],
            F: [
                { word: t('interest'), description: t('interesttext') },
            ],
            G: [
                { word: t('yield'), description: t('yieldtext') },
                { word: t('return'), description: t('returntext') },
            ],
            H: [
                { word: t('stock'), description: t('stocktext') },
            ],
            K: [
                { word: t('leverage'), description: t('leveragetext') },
                { word: t('commission'), description: t('commissiontext') },
            ],
            L: [
                { word: t('liquidity'), description: t('liquiditytext') },
            ],
            M: [
                { word: t('margin'), description: t('margintext') },
            ],
            O: [
                { word: t('option'), description: t('optiontext') },
            ],
            Ö: [
                { word: t('equity'), description: t('equitytext') },
            ],
            P: [
                { word: t('portfolio'), description: t('portfoliotext') },
                { word: t('currency'), description: t('currencytext') },
            ],
            R: [
                { word: t('risk'), description: t('risktext') },
            ],
            S: [
                { word: t('ask'), description: t('asktext') },
                { word: t('spread'), description: t('spreadtext') },
                { word: t('capital'), description: t('capitaltext') },
            ],
            T: [
                { word: t('dividend'), description: t('dividendtext') },
                { word: t('bond'), description: t('bondtext') },
                { word: t('derivative'), description: t('derivativetext') },
            ],
            V: [
                { word: t('volatility'), description: t('volatilitytext') },
                { word: t('future'), description: t('futuretext') },
            ],
            Y: [
                { word: t('investment'), description: t('investmenttext') },
            ],
        },
        en: {
            A: [
                {
                    word: t('arbitrage'),
                    description: t('arbitragetext')
                },
                {
                    word: t('ask'),
                    description: t('asktext')
                }
            ],
            B: [
                {
                    word: t('bid'),
                    description: t('bidtext')
                },
                {
                    word: t('bull'),
                    description: t('bulltext')
                },
                {
                    word: t('bear'),
                    description: t('beartext')
                },
                {
                    word: t('broker'),
                    description: t('brokertext')
                },
                {
                    word: t('bond'),
                    description: t('bondtext')
                }
            ],
            C: [
                {
                    word: t('commission'),
                    description: t('commissiontext')
                },
                {
                    word: t('currency'),
                    description: t('currencytext')
                },
                {
                    word: t('capital'),
                    description: t('capitaltext')
                }
            ],
            D: [
                {
                    word: t('dividend'),
                    description: t('dividendtext')
                },
                {
                    word: t('derivative'),
                    description: t('derivativetext')
                },
                {
                    word: t('deflation'),
                    description: t('deflationtext')
                }
            ],
            E: [
                {
                    word: t('equity'),
                    description: t('equitytext')
                },
                {
                    word: t('exchange'),
                    description: t('exchangetext')
                }
            ],
            F: [
                {
                    word: t('future'),
                    description: t('futuretext')
                }
            ],
            I: [
                {
                    word: t('interest'),
                    description: t('interesttext')
                },
                {
                    word: t('investment'),
                    description: t('investmenttext')
                },
                {
                    word: t('inflation'),
                    description: t('inflationtext')
                }
            ],
            L: [
                {
                    word: t('leverage'),
                    description: t('leveragetext')
                },
                {
                    word: t('liquidity'),
                    description: t('liquiditytext')
                }
            ],
            M: [
                {
                    word: t('margin'),
                    description: t('margintext')
                }
            ],
            O: [
                {
                    word: t('openposition'),
                    description: t('openpositiontext')
                },
                {
                    word: t('option'),
                    description: t('optiontext')
                }
            ],
            P: [
                {
                    word: t('portfolio'),
                    description: t('portfoliotext')
                }
            ],
            R: [
                {
                    word: t('risk'),
                    description: t('risktext')
                },
                {
                    word: t('return'),
                    description: t('returntext')
                }
            ],
            S: [
                {
                    word: t('spread'),
                    description: t('spreadtext')
                },
                {
                    word: t('stock'),
                    description: t('stocktext')
                }
            ],
            V: [
                {
                    word: t('volatility'),
                    description: t('volatilitytext')
                }
            ],
            Y: [
                {
                    word: t('yield'),
                    description: t('yieldtext')
                }
            ],
        }
    };

    const currentDictionary = dictionary[i18n.language] || {};

    const alphabet = Object.keys(currentDictionary); // Dildeki mevcut harfleri al

    const toggleLetter = (letter) => {
        if (selectedLetter === letter) {
            setSelectedLetter(null); // Seçili harfi kaldır, tüm item'leri göster
        } else {
            setSelectedLetter(letter); // Harfi seç, sadece o harfin item'lerini göster
        }
    };

    return (
        <div className='trading-platforms-component-content'>
            <h3 className="component-title">
                {t("educationAndKnowledgeTitle")}
            </h3>
            <p className="component-paragraph mt-3">
                {t("educationAndKnowledgeContent")}
            </p>
            <p className="component-paragraph mt-3">
                {t("academyContent")}
            </p>
            <div className="row justify-content-center mt-5">
                <div className="col-lg-6">
                    <div className="academy-card d-flex flex-column justify-content-end shadow" data-bs-toggle="modal" data-bs-target="#dictionaryModal" style={{ backgroundImage: `url(${DictImg})`, backgroundPosition: "50% 75%", cursor: "pointer" }}>
                        <h5>{t("dictionary")}</h5>
                    </div>
                    <div>
                        {/* Modal */}
                        <div className="modal modal-xl fade" id="dictionaryModal" tabIndex={-1} aria-labelledby="dictionaryModalLabel" aria-hidden="true">
                            <div className="modal-dialog modal-dialog-centered">
                                <div className="modal-content">
                                    <div className="modal-header">
                                        <h1 className="modal-title fs-5" id="dictionaryModalLabel">{t("dictionary")}</h1>
                                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
                                    </div>
                                    <div className="modal-body">
                                        {/* Harf Filtreleme Butonları */}
                                        <div className='text-center'>
                                            <div className="btn-group mb-3" role="group" aria-label="Alphabet Filter">
                                                {alphabet.map((letter) => (
                                                    <button
                                                        key={letter}
                                                        type="button"
                                                        className={`dict-btn ${selectedLetter === letter ? "primary-v2-color" : ""}`}
                                                        onClick={() => toggleLetter(letter)}
                                                    >
                                                        {letter}
                                                    </button>
                                                ))}
                                            </div>
                                        </div>
                                        {/* Accordion Yapısı */}
                                        <div className="accordion" id="dictionaryAccordion">
                                            {selectedLetter
                                                ? // Sadece seçilen harfe göre kelimeler gösteriliyor
                                                currentDictionary[selectedLetter]?.map((item, index) => (
                                                    <div className="accordion-item" key={item.word + index}>
                                                        <h2 className="accordion-header" id={`heading${index}`}>
                                                            <button
                                                                className="accordion-button collapsed"
                                                                type="button"
                                                                data-bs-toggle="collapse"
                                                                data-bs-target={`#collapse${index}`}
                                                                aria-expanded="false"
                                                                aria-controls={`collapse${index}`}
                                                            >
                                                                {item.word}
                                                            </button>
                                                        </h2>
                                                        <div
                                                            id={`collapse${index}`}
                                                            className="accordion-collapse collapse"
                                                            aria-labelledby={`heading${index}`}
                                                            data-bs-parent="#dictionaryAccordion"
                                                        >
                                                            <div className="accordion-body">{item.description}</div>
                                                        </div>
                                                    </div>
                                                ))
                                                : // Tüm harfler ve kelimeler accordion şeklinde gösteriliyor
                                                Object.keys(currentDictionary).map((letter) =>
                                                    currentDictionary[letter].map((item, index) => (
                                                        <div className="accordion-item" key={item.word + index}>
                                                            <h2 className="accordion-header" id={`heading${item.word}${index}`}>
                                                                <button
                                                                    className="accordion-button collapsed"
                                                                    type="button"
                                                                    data-bs-toggle="collapse"
                                                                    data-bs-target={`#collapse${item.word}${index}`}
                                                                    aria-expanded="false"
                                                                    aria-controls={`collapse${item.word}${index}`}
                                                                >
                                                                    {item.word}
                                                                </button>
                                                            </h2>
                                                            <div
                                                                id={`collapse${item.word}${index}`}
                                                                className="accordion-collapse collapse"
                                                                aria-labelledby={`heading${item.word}${index}`}
                                                                data-bs-parent="#dictionaryAccordion"
                                                            >
                                                                <div className="accordion-body">{item.description}</div>
                                                            </div>
                                                        </div>
                                                    ))
                                                )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-lg-6 mt-lg-0 mt-md-0 mt-3">
                    <Link to="/register" className='text-decoration-none'>
                        <div className="academy-card d-flex flex-column justify-content-end shadow" style={{ backgroundImage: `url(${EBookImg})` }}>
                            <h5>{t("ebook")}</h5>
                        </div>
                    </Link>
                </div>
            </div>
        </div>
    );
}

export default AcademyComponent;
