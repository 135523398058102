import React from 'react'
import SubpageBanner from '../components/SubpageBanner'

const NewsFeed = () => {
    return (
        <div className='news-feed-content'>
            <SubpageBanner title="Haberler" />
        </div>
    )
}

export default NewsFeed