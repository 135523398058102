import React from 'react'
import { useTranslation } from "react-i18next";
import CardImg1 from "../assets/img/homepage/icons/icon_Guvenirlilik.svg"
import CardImg2 from "../assets/img/homepage/icons/icon_seffaflik.svg"
import CardImg3 from "../assets/img/homepage/icons/icon_inovasyon.svg"
import CardImg4 from "../assets/img/homepage/icons/icon_Uzman-Destek.svg"
import CardImg5 from "../assets/img/homepage/icons/icon_urun-cesitliligi.svg"
import CardImg6 from "../assets/img/homepage/icons/icon_Egitim-ve-Analiz.svg"

const Services = () => {
    const { t } = useTranslation();
    const dataList = [
        {
            icon: CardImg1,
            title: t("reliabilityTitle"),
            text: t("reliabilityContent")
        },
        {
            icon: CardImg2,
            title: t("transparencyTitle"),
            text: t("transparencyContent")
        },
        {
            icon: CardImg3,
            title: t("innovationTitle"),
            text: t("innovationContent")
        },
        {
            icon: CardImg4,
            title: t("expertSupportTitle"),
            text: t("expertSupportContent")
        },
        {
            icon: CardImg5,
            title: t("productVarietyTitle"),
            text: t("productVarietyContent")
        },
        {
            icon: CardImg6,
            title: t("educationAndAnalysisTitle"),
            text: t("educationAndAnalysisContent")
        },
    ]
    return (
        <div className="online-banking-container">
            <div className="container py-5">
                <h5 className="component-subtitle text-center">{t("whySilverInvestTitle")}</h5>
                <h3 className="component-title my-3 text-dark text-center mb-5 mx-auto">{t("whySilverInvestContent")}</h3>
                <div className="row">
                    {
                        dataList.map((e, index) => (
                            <div className="col-lg-4 col-md-6 mt-4" key={index}>
                                <div className='service-item h-100'>
                                    <div className="row">
                                        <div className="col-lg-2">
                                            <img width="45px" height="45px" src={e.icon} alt="bonds" />
                                        </div>
                                        <div className="col-lg-9 ps-lg-4 ps-2 mt-lg-0 mt-3">
                                            <h4 className=''>{e.title}</h4>
                                            <p className="mb-0">{e.text}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))
                    }
                </div>
            </div>
        </div>
    )
}

export default Services;