import React from 'react'
import { useTranslation } from "react-i18next";

const Faqs = () => {
    const { t } = useTranslation();
    const leftSideItems = [
        {
            id: 1,
            question: t("faqquestion1"),
            answer: t("faqanswer1")
        },
        {
            id: 3,
            question: t("faqquestion3"),
            answer: t("faqanswer3")
        },
        {
            id: 5,
            question: t("faqquestion5"),
            answer: t("faqanswer5")
        },
        {
            id: 7,
            question: t("faqquestion7"),
            answer: t("faqanswer7")
        },
        {
            id: 9,
            question: t("faqquestion9"),
            answer: t("faqanswer9")
        },
        {
            id: 11,
            question: t("faqquestion11"),
            answer: t("faqanswer11")
        },
        {
            id: 13,
            question: t("faqquestion13"),
            answer: t("faqanswer13")
        },
    ]

    const rightSideItems = [
        {
            id: 2,
            question: t("faqquestion2"),
            answer: t("faqanswer2")
        },
        {
            id: 4,
            question: t("faqquestion4"),
            answer: t("faqanswer4")
        },
        {
            id: 6,
            question: t("faqquestion6"),
            answer: t("faqanswer6")
        },
        {
            id: 8,
            question: t("faqquestion8"),
            answer: t("faqanswer8")
        },
        {
            id: 10,
            question: t("faqquestion10"),
            answer: t("faqanswer10")
        },
        {
            id: 12,
            question: t("faqquestion12"),
            answer: t("faqanswer12")
        },
        {
            id: 14,
            question: t("faqquestion14"),
            answer: t("faqanswer14")
        },
    ]

    return (
        <>
            <div className='container my-5'>
                <h3 className="component-title">{t("faqSubtite")}</h3>
                <p className="component-paragraph mt-3">
                    {t("faqContent")}
                </p>
                <div className="accordion" id="accordionPanelsStayOpenExample">
                    <div className="row mt-4">
                        <div className="col-lg-6">
                            <div className="row">
                                {
                                    leftSideItems.map((e) => (
                                        <div className="col-lg-12 mt-4">
                                            <div className="accordion-item">
                                                <h2 className="accordion-header">
                                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target={`#panelsStayOpen-collapse${e.id}`} aria-expanded="false" aria-controls={`panelsStayOpen-collapse${e.id}`}>
                                                        {e.question}
                                                    </button>
                                                </h2>
                                                <div id={`panelsStayOpen-collapse${e.id}`} className="accordion-collapse collapse">
                                                    <div className="accordion-body">
                                                        {e.answer}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ))
                                }
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="row">
                                {
                                    rightSideItems.map((e) => (
                                        <div className="col-lg-12 mt-4">
                                            <div className="accordion-item">
                                                <h2 className="accordion-header">
                                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target={`#panelsStayOpen-collapse${e.id}`} aria-expanded="false" aria-controls={`panelsStayOpen-collapse${e.id}`}>
                                                        {e.question}
                                                    </button>
                                                </h2>
                                                <div id={`panelsStayOpen-collapse${e.id}`} className="accordion-collapse collapse">
                                                    <div className="accordion-body">
                                                        {e.answer}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ))
                                }
                            </div>
                        </div>
                    </div>
                </div>
                <p className="component-paragraph mt-4">
                    {t("faqFooter")}
                </p>
            </div>
        </>
    )
}

export default Faqs