import React from 'react'
import Logo from "../assets/img/silver/svg/horizontal-logo-disi-cropped.svg"
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

const Footer = () => {
    const { t } = useTranslation();
    return (
        <div className='footer-content'>
            <div className="container">
                <div className="row">
                    <div className="col-lg-6 mb-lg-0 mb-4">
                        <div className="w-75">
                            <img src={Logo} alt="logo" width="125px" />
                            <p className="mb-0 mt-4">
                                {t("footertext")}
                            </p>
                        </div>
                    </div>
                    <div className="col-lg-3 mb-lg-0 mb-4">
                        <h5 className='mb-4 footer-title'>{t("discover")}</h5>
                        <ul className='p-0 mb-0 list-unstyled footer-navigation-list'>
                            <li>
                                <Link to="/academy">{t("academy")}</Link>
                            </li>
                            <li>
                                <Link to="/about">{t("about")}</Link>
                            </li>
                            <li>
                                <Link to="/trading-platforms">{t("tradingplatforms")}</Link>
                            </li>
                            <li>
                                <Link to="/faq">{t("faq")}</Link>
                            </li>
                            <li>
                                <Link to="/investment-products">{t("investmentproducts")}</Link>
                            </li>
                            <li>
                                <Link to="/account-types">{t("accountTypesTitle")}</Link>
                            </li>
                            <li>
                                <Link to="/promotions">{t("promotionsTitle")}</Link>
                            </li>
                        </ul>
                    </div>
                    <div className="col-lg-3">
                        <h5 className='mb-4 footer-title'>{t("contact")}</h5>
                        <ul className='p-0 mb-0 list-unstyled footer-navigation-list'>
                            <li>
                                <span className='fw-bold'>{t("email")}:</span> <a href="mailto:support@silverinvestmentsgroup.com">support@silverinvestmentsgroup.com</a>
                            </li>
                            <li>
                                <span className='fw-bold'>{t("phone")}:</span>  <a href="https://wa.me/447585748974" target='_blank'>+44 7585 748 974</a>
                            </li>
                            <li>
                                <span className='fw-bold'>{t("location")}:</span> <a href="https://maps.app.goo.gl/TAB4cXhWHPNtjKUe8" target='_blank'> {t("locationaddress")}</a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Footer;