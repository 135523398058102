import React from 'react'
import SubpageBanner from '../components/SubpageBanner'
import Platforms from '../components/Platforms'
import AdvantagesComponent from '../components/AdvantagesComponent'
import TextCta from '../components/TextCta'
import AdvantagesImg from "../assets/img/advantages/b-Avantajlar.png"
import { useTranslation } from 'react-i18next'

const Advantages = () => {
    const { t } = useTranslation()
    return (
        <div className='trading-platforms-content'>
            <SubpageBanner title={t("advantagesOfSilverInvestTitle")} image={AdvantagesImg} />
            <div className="container">
                <AdvantagesComponent />
            </div>
            <TextCta title={t("advtextcta")} />
            <Platforms />
        </div>
    )
}

export default Advantages