import React from 'react'
import { useTranslation } from "react-i18next";

const WhyChoose = () => {
    const { t } = useTranslation();
    const dataList = [
        {
            icon: "https://img.icons8.com/ios/45/ffffff/shield.png",
            title: t("awardWinningSupportTeamTitle"),
            description: t("awardWinningSupportTeamContent")
        },
        {
            icon: "https://img.icons8.com/ios/45/ffffff/helping-hand.png",
            title: t("regulationAndLicensingTitle"),
            description: t("regulationAndLicensingContent")
        },
        {
            icon: "https://img.icons8.com/ios/45/ffffff/innovation.png",
            title: t("chartsAndToolsTitle"),
            description: t("chartsAndToolsContent")
        },
        {
            icon: "https://img.icons8.com/ios/45/ffffff/headset.png",
            title: t("innovationSectionTitle"),
            description: t("innovationSectionContent")
        }
    ]
    return (
        <div className="why-choose-container">
            <div className="container">
                <h3 className="component-title my-3 text-dark text-center">{t("securityTitle")}</h3>
                <div className="row mt-5">
                    {
                        dataList.map((e, index) => (
                            <div className="col-lg-3 mb-4" key={index}>
                                <div className="service-item why-choose-card h-100">
                                    <div className="why-choose-img">
                                        <img width="45" height="45" src={e.icon} alt="why-choose-icon" />
                                    </div>
                                    <h4 className='my-4'>
                                        {e.title}
                                    </h4>
                                    <p className="mb-0">
                                        {e.description}
                                    </p>
                                </div>
                            </div>
                        ))
                    }
                </div>
            </div>
        </div>
    )
}

export default WhyChoose;