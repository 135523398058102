import React from 'react'
import Slider from "react-slick";
import Banner1 from "../assets/img/homepage/b-Home.png"
import { useTranslation } from 'react-i18next';

const Banner = () => {
    const { t } = useTranslation();
    const SampleNextArrow = (props) => {
        const { className, style, onClick } = props;
        return (
            <div
                className={className}
                style={{ ...style, display: "flex", background: "red", alignItems: "center", justifyContent: "center" }}
                onClick={onClick}
            />
        );
    }

    const SamplePrevArrow = (props) => {
        const { className, style, onClick } = props;
        return (
            <div
                className={className}
                style={{ ...style, display: "flex", background: "green", alignItems: "center", justifyContent: "center" }}
                onClick={onClick}
            />
        );
    }

    const settings = {
        dots: true,
        arrows: false,
        infinite: true,
        autoplay: false,
        autoplayspeed: 5000,
        slidesToShow: 1,
        slidesToScroll: 1,
        nextArrow: <SampleNextArrow />,
        prevArrow: <SamplePrevArrow />
    };

    const sliderItems = [
        {
            image: Banner1,
            title: "Silver Invest ile Sınırları Aşın, Geleceğinizi Güvence Altına Alın!",
        },
        {
            image: Banner1,
            title: "Test Slider Items Daha Uzun Uzun Bir Yazı",
        },
        {
            image: Banner1,
            title: "Pek de Uzun Olmayan Bir Yazı",
            button: "Hakkımızda"
        },
    ]

    return (
        <div className="container-fluid px-0">
            {/* <Slider {...settings}>
                {
                    sliderItems.map((e, index) => (
                        <div className="banner-slide">
                            <img src={e.image} className="banner-image" width="100%" alt="slider-image" />
                            <div className="banner-overlay">
                                <div className="container">
                                    <h3>
                                        {e.title}
                                    </h3>
                                    {
                                        e.button &&
                                        <button className="btn btn-secondary-v2 mt-4 py-3 px-5">
                                            {e.button}
                                        </button>
                                    }
                                </div>
                            </div>
                        </div>
                    ))
                }
            </Slider> */}
            <div className="banner-slide">
                <div className='banner-image'></div>
                <div className="banner-overlay">
                    <div className="container">
                        <h3>
                            {t("homePageSubtitle")} <br /> {t("homePageSubtitle2")}
                        </h3>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Banner