import React from 'react'
import AccTypesImg1 from "../assets/img/account-types/SILVER-hesap.png"
import AccTypesImg2 from "../assets/img/account-types/GOLD-hesap.png"
import AccTypesImg3 from "../assets/img/account-types/VIP-hesap.png"
import AccTypesImg4 from "../assets/img/account-types/islami-hesap.png"
import AccTypesImg5 from "../assets/img/account-types/DEMO-hesap.png"
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

const AccountTypesComponent = () => {
    const { t } = useTranslation()
    const dataList = [
        {
            img: AccTypesImg1,
            title: t("silverAccountTitle"),
            text: t("silverAccountContent")
        },
        {
            img: AccTypesImg2,
            title: t("goldAccountTitle"),
            text: t("goldAccountContent")
        },
        {
            img: AccTypesImg3,
            title: t("vipAccountTitle"),
            text: t("vipAccountContent")
        },
        {
            img: AccTypesImg4,
            title: t("islamicAccountTitle"),
            text: t("islamicAccountContent")
        },
        {
            img: AccTypesImg5,
            title: t("demoAccountTitle"),
            text: t("demoAccountContent"),
            button: t("createdemoacc")
        },
    ]
    return (
        <div className='trading-platforms-component-content'>
            <h3 className="component-title">{t("specialacc")}</h3>
            <h3 className="component-title">{t("specialacc2")}</h3>
            <p className="component-paragraph mt-3">
                {t("accountTypesContent")}
            </p>
            <div className="row justify-content-center gy-4 mt-5">
                {
                    dataList.map((e, index) => (
                        <div className="col-lg-6">
                            <div className="account-types-card d-flex flex-column justify-content-end shadow" style={{ backgroundImage: `url(${e.img})` }}>
                                <div className='account-types-card-text-content'>
                                    <h5 className='mb-3'>{e.title}</h5>
                                    <p className="mb-4">
                                        {e.text}
                                    </p>
                                    <Link to="/register">
                                        <button className='btn btn-primary-v2'>
                                            {e.button ? e.button : t("createacc")}
                                        </button>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    ))
                }
            </div>
        </div>
    )
}

export default AccountTypesComponent