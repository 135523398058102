import React from 'react'
import SubpageBanner from '../components/SubpageBanner'
import AcademyImg from "../assets/img/academy/egitim-ve-kaynaklar.png"
import AcademyComponent from '../components/AcademyComponent'
import Platforms from '../components/Platforms'
import { useTranslation } from 'react-i18next'

const Academy = () => {
    const { t } = useTranslation()
    return (
        <div className='account-types-content'>
            <SubpageBanner title={t("academy")} image={AcademyImg} />
            <div className="container">
                <AcademyComponent />
            </div>
            <Platforms />
        </div>
    )
}

export default Academy