import React from 'react'
import SubpageBanner from '../components/SubpageBanner'
import AboutUs from '../components/AboutUs'
import WhyChoose from '../components/WhyChoose'
import Platforms from '../components/Platforms'
import Vision from '../components/Vision'
import { useTranslation } from "react-i18next";
import Mission from '../components/Mission'
import AboutUsDetail from '../components/AboutUsDetail'
import AboutImg from "../assets/img/about/b-Hakkimizda.png"

const About = () => {
    const { t } = useTranslation();
    return (
        <div className='about-content'>
            <SubpageBanner title={t("about")} image={AboutImg} />
            <AboutUsDetail />
            {/* <Vision /> */}
            {/* <Mission /> */}
            <WhyChoose />
            <Platforms />
        </div>
    )
}

export default About