import React, { useEffect, useState } from 'react'
import Mockup from "../assets/img/mockup-1.png"
import Android from "../assets/img/android.png"
import Ios from "../assets/img/ios.png"
import { useTranslation } from "react-i18next";
import { Link } from 'react-router-dom';

const TextCta = ({ title }) => {
    const { t } = useTranslation();

    return (
        <div className="advantages-cta-content">
            <div className="container-fluid">
                <div className="w-75 mx-auto platform-text-area">
                    <h3 className="component-title text-white text-center">
                        {title}
                    </h3>
                    <div className='text-center mt-5'>
                        <Link to="/register">
                            <button className='btn btn-secondary-v3'>
                                {t("createaccount")}
                            </button>
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default TextCta