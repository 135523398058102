import React from 'react'
import SubpageBanner from '../components/SubpageBanner'
import Faqs from '../components/Faqs';
import FaqImg from "../assets/img/faq/b-SSS.png"
import { useTranslation } from "react-i18next";

const Faq = () => {
    const { t } = useTranslation();
    return (
        <>
            <SubpageBanner title={t("faqTitle")} image={FaqImg} />
            <Faqs />
        </>
    )
}

export default Faq;