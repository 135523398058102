import React from 'react'
import Banner from '../components/Banner';
import AboutUs from '../components/AboutUs';
import AboutUs2 from '../components/AboutUs2';
import Services from '../components/Services';
import Platforms from '../components/Platforms';
import ContactForm from '../components/ContactForm';

const Homepage = () => {
    return (
        <>
            <Banner />
            <AboutUs />
            <Services />
            <Platforms />
            <AboutUs2 />
            <ContactForm />
        </>
    )
}

export default Homepage