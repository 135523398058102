import React from 'react'
import Icon1 from "../assets/img/trading-platforms/icons/icon_kullanıcı dostu arayüz.svg"
import Icon2 from "../assets/img/trading-platforms/icons/icon_gelişmiş grafik ve analiz araçları.svg"
import Icon3 from "../assets/img/trading-platforms/icons/icon_anlık piyasa takibi ve bildirim.svg"
import Icon4 from "../assets/img/trading-platforms/icons/icon_güçlü güvenlik özellikleri.svg"
import Icon5 from "../assets/img/trading-platforms/icons/icon_çoklu cihaz uyumluluğu.svg"
import Icon6 from "../assets/img/trading-platforms/icons/icon_hılzı ve verimli işlem yürütme.svg"
import { useTranslation } from 'react-i18next'

const TradingPlatformsComponent = () => {
    const { t } = useTranslation();
    const dataList = [
        {
            icon: Icon1,
            title: t("tpitem1title"),
            text: t("tpitem1desc")
        },
        {
            icon: Icon2,
            title: t("tpitem2title"),
            text: t("tpitem2desc")
        },
        {
            icon: Icon3,
            title: t("tpitem3title"),
            text: t("tpitem3desc")
        },
        {
            icon: Icon4,
            title: t("tpitem4title"),
            text: t("tpitem4desc")
        },
        {
            icon: Icon5,
            title: t("tpitem5title"),
            text: t("tpitem5desc")
        },
        {
            icon: Icon6,
            title: t("tpitem6title"),
            text: t("tpitem6desc")
        },
    ]
    return (
        <div className='trading-platforms-component-content'>
            <h3 className="component-title">{t("tradingPlatformTitle")} <br /> {t("tradingPlatformTitle2")}</h3>
            <p className="component-paragraph mt-3">
                {t("tradingplatformsdesc")}
            </p>
            <div className="row gy-4 mt-5">
                {
                    dataList.map((e, index) => (
                        <div className="col-lg-6 mt-5" key={index}>
                            <div className="d-flex align-items-start">
                                <div className="item-icon me-3">
                                    <img width="30" height="30" src={e.icon} alt="checked--v1" />
                                </div>
                                <div className="d-flex flex-column">
                                    <h5 style={{ fontWeight: "600" }}>{e.title}</h5>
                                    <p className="mb-0 text-muted">
                                        {e.text}
                                    </p>
                                </div>
                            </div>
                        </div>
                    ))
                }
            </div>
        </div>
    )
}

export default TradingPlatformsComponent