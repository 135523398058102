import React from 'react'
import SubpageBanner from '../components/SubpageBanner'
import TradingPlatformsComponent from '../components/TradingPlatformsComponent'
import Platforms from '../components/Platforms'
import TradingPlatformsBanner from "../assets/img/trading-platforms/b-islem-platformu.png"
import { useTranslation } from 'react-i18next'

const TradingPlatforms = () => {
    const { t } = useTranslation();
    return (
        <div className='trading-platforms-content'>
            <SubpageBanner title={t("tradingplatforms")} image={TradingPlatformsBanner} />
            <div className="container">
                <TradingPlatformsComponent />
            </div>
            <Platforms />
        </div>
    )
}

export default TradingPlatforms