import React from 'react'
import { useTranslation } from "react-i18next";
import AboutImg from "../assets/img/homepage/Artboard-1.png"

const AboutUs = () => {
    const { t } = useTranslation();
    return (
        <div className='about-us-content my-5'>
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-lg-6 col-md-6 position-relative">
                        <img src={AboutImg} className='about-us-image' alt="banner-image" />
                        {/* <div className="experience-box">
                            <h2 className='mb-1'>8</h2>
                            <h4 className='text-center'>{t("yearsexperience")}</h4>
                        </div> */}
                    </div>
                    <div className="col-lg-6 col-md-6">
                        <h3 className="component-title my-3">{t("discoverSilverInvestTitle")}</h3>
                        <p className="component-paragraph mt-4">
                            {t("discoverSilverInvestContent")}
                        </p>
                        <div className="about-us-icons mt-5">
                            <div className='d-flex align-items-center'>
                                <div className='icons-content me-4'>
                                    <img width="35" height="35" src="https://img.icons8.com/ios/50/3967AF/mission-of-a-company.png" alt="bitcoin--v1" />
                                </div>
                                <div className="d-flex flex-column">
                                    <h4>{t("visionTitle")}</h4>
                                    <p className='mb-0'>{t("visionContent")}</p>
                                </div>
                            </div>
                            <div className='d-flex align-items-center mt-4'>
                                <div className='icons-content me-4'>
                                    <img width="35" height="35" src="https://img.icons8.com/ios/50/3967AF/goal--v1.png" alt="checked--v1" />
                                </div>
                                <div className="d-flex flex-column">
                                    <h4>{t("missionTitle")}</h4>
                                    <p className='mb-0'>{t("missionContent")}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AboutUs