import React, { useEffect, useState } from 'react';
import Logo from "../assets/img/silver/svg/horizontal-logo-color-cropped.svg";
import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const Header = () => {
    const { t } = useTranslation();

    const [scaurl, setScaurl] = useState("sca." + window.location.host.replace("www.", ""));

    return (
        <div className='header-content shadow-sm'>
            <div className="container py-4">
                <div className="d-flex align-items-center justify-content-between">
                    <div className="header-logo">
                        <NavLink to="/">
                            <img src={Logo} alt="logo" width="125px" />
                        </NavLink>
                    </div>
                    <div className="navigation">
                        <ul className='list-unstyled mb-0 d-flex align-items-center navigation-content'>
                            <li>
                                <NavLink
                                    to="/"
                                    className={({ isActive }) => (isActive ? 'active' : '')}
                                >
                                    {t("homepage")}
                                </NavLink>
                            </li>
                            <li>
                                <NavLink
                                    to="/academy"
                                    className={({ isActive }) => (isActive ? 'active' : '')}
                                >
                                    {t("academy")}
                                </NavLink>
                            </li>
                            <li>
                                <NavLink
                                    to="/about"
                                    className={({ isActive }) => (isActive ? 'active' : '')}
                                >
                                    {t("about")}
                                </NavLink>
                            </li>
                            <li>
                                <NavLink
                                    to="/trading-platforms"
                                    className={({ isActive }) => (isActive ? 'active' : '')}
                                >
                                    {t("tradingplatforms")}
                                </NavLink>
                            </li>
                            <li>
                                <NavLink
                                    to="/faq"
                                    className={({ isActive }) => (isActive ? 'active' : '')}
                                >
                                    {t("faq")}
                                </NavLink>
                            </li>
                            <li>
                                <NavLink
                                    to="/investment-products"
                                    className={({ isActive }) => (isActive ? 'active' : '')}
                                >
                                    {t("investmentproducts")}
                                </NavLink>
                            </li>
                            <li>
                                <NavLink to="/register">
                                    <button className='btn btn-primary-v2'>{t("createaccount")}</button>
                                </NavLink>
                            </li>
                            <li>
                                <a href={`https://${scaurl}`} target='_blank'>
                                    <button className='btn btn-secondary-v2'>{t("login")}</button>
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Header;
