import React from 'react'
import Icon1 from "../assets/img/advantages/icons/icon_Güvenirlilik.svg"
import Icon2 from "../assets/img/advantages/icons/icon_Eğitim ve Analiz.svg"
import Icon3 from "../assets/img/advantages/icons/icon_Ürün Çeşitliliği.svg"
import Icon4 from "../assets/img/advantages/icons/icon_Şeffaflık.svg"
import Icon5 from "../assets/img/advantages/icons/icon_çoklu cihaz uyumluluğu.svg"
import Icon6 from "../assets/img/advantages/icons/icon_anlık piyasa takibi ve bildirim.svg"
import Icon7 from "../assets/img/advantages/icons/icon_hılzı ve verimli işlem yürütme.svg"
import Icon8 from "../assets/img/advantages/icons/icon_Uzman Destek.svg"
import { useTranslation } from 'react-i18next'

const AdvantagesComponent = () => {
    const { t } = useTranslation()
    const dataList = [
        {
            icon: Icon1,
            title: t("securitySectionTitle"),
            text: t("securitySectionContent")
        },
        {
            icon: Icon2,
            title: t("knowledgeIsPowerTitle"),
            text: t("knowledgeIsPowerContent")
        },
        {
            icon: Icon3,
            title: t("platformOptionsTitle"),
            text: t("platformOptionsContent")
        },
        {
            icon: Icon4,
            title: t("educationSectionTitle"),
            text: t("educationSectionContent")
        },
        {
            icon: Icon5,
            title: t("investmentJourneyTitle"),
            text: t("investmentJourneyContent")
        },
        {
            icon: Icon6,
            title: t("beyondInvestmentTitle"),
            text: t("beyondInvestmentContent")
        },
        {
            icon: Icon7,
            title: t("advitemtitle"),
            text: t("advitemtext")
        },
        {
            icon: Icon8,
            title: t("advitemtitle2"),
            text: t("advitemtext2")
        },
    ]
    return (
        <div className='trading-platforms-component-content'>
            <h3 className="component-title">{t("advitemtitle3")}</h3>
            <p className="component-paragraph mt-3">
                {t("advantagesOfSilverInvestContent")}
            </p>
            <div className="row gy-4 mt-5">
                {
                    dataList.map((e, index) => (
                        <div className="col-lg-6 mt-5" key={index}>
                            <div className="d-flex align-items-start">
                                <div className="item-icon me-3">
                                    <img width="30" height="30" src={e.icon} alt="checked--v1" />
                                </div>
                                <div className="d-flex flex-column">
                                    <h5 style={{ fontWeight: "600" }}>{e.title}</h5>
                                    <p className="mb-0 text-muted">
                                        {e.text}
                                    </p>
                                </div>
                            </div>
                        </div>
                    ))
                }
            </div>
        </div>
    )
}

export default AdvantagesComponent