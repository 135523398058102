import React, { useEffect, useState } from 'react'
import Mockup from "../assets/img/homepage/phone-mockup-220x425.png"
import Android from "../assets/img/android.png"
import Ios from "../assets/img/ios.png"
import { useTranslation } from "react-i18next";

const Platforms = () => {
    const [isAbout, setIsAbout] = useState(false)
    const [app, setApp] = useState({
        ios: "",
        android: ""
    })
    const { t } = useTranslation();

    useEffect(() => {
        getApp()
        window.location.pathname.includes("about") && setIsAbout(true)
    }, [])

    const getApp = () => {
        const location = window.location.origin
        let url;
        if (location.includes("localhost") || location.includes("test")) {
            url = "https://silver.testmedici.com/api/Global/Apps"
        } else {
            url = `${location}/api/Global/Apps`
        }

        fetch(url)
            .then(response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok ' + response.statusText);
                }
                return response.json();
            })
            .then(data => {
                setApp({
                    ios: data.ios,
                    android: data.android
                })
            })
            .catch(error => {
                console.error('There was a problem with the fetch operation:', error);
            });
    }

    return (
        <div className={`platforms-content py-5 ${isAbout ? "about" : ""}`}>
            <div className="container-fluid">
                <div className="row align-items-center">
                    <div className="col-lg-4 col-md-4 text-end platform-mockup-image">
                        <img src={Mockup} alt="mockup" width="45%" />
                    </div>
                    <div className="col-lg-8 col-md-8">
                        <div className="w-75 mx-auto platform-text-area">
                            {/* <h5 className="component-subtitle">{t("homepagemobileapptitle1")}</h5> */}
                            <h3 className="component-title my-3 text-dark">
                                {t("discoverSilverInvestTitle2")}
                            </h3>
                            {/* <p className="component-paragraph mt-4">
                                {t("mobileapptext")}
                            </p> */}
                            <div className='d-flex flex-wrap align-items-center mt-5 platform-app-icons'>
                                <a href={app.android} className='text-decoration-none me-lg-0 me-md-3' target='_blank'>
                                    <button className="mobile-app d-flex align-items-center justify-content-between me-lg-4 me-md-0 me-0 mb-lg-0 mb-md-0 mb-3">
                                        <img src={Android} alt="android" className='me-3' />
                                        <div className="d-flex flex-column align-items-start">
                                            <div className='mobile-app-subtitle'>{t("downloadnow")}</div>
                                            <div className='mobile-app-title'>Google Play</div>
                                        </div>
                                    </button>
                                </a>
                                <a href={app.ios} className='text-decoration-none' target='_blank'>
                                    <button className="mobile-app d-flex align-items-center justify-content-between">
                                        <img src={Ios} alt="android" className='me-3' />
                                        <div className="d-flex flex-column align-items-start">
                                            <div className='mobile-app-subtitle'>{t("downloadnow")}</div>
                                            <div className='mobile-app-title'>Apple Store</div>
                                        </div>
                                    </button>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Platforms