import React, { useState, useEffect } from 'react';
import './App.css';
import Header from './components/Header';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Homepage from './pages/Homepage';
import Footer from './components/Footer';
import About from './pages/About';
import TradingPlatforms from './pages/TradingPlatforms';
import Register from './pages/Register';
import Advantages from './pages/Advantages';
import NewsFeed from './pages/NewsFeed';
// import Campaigns from './pages/Campaigns';
import ScrollToTop from "./components/ScrollToTop"
import MobileHeader from './components/MobileHeader';
import "./i18";
import AnnouncementBar from './components/AnnouncementBar';
import Faq from './pages/Faq';
import InvestmentProducts from './pages/InvestmentProducts';
import AccountTypes from './pages/AccountTypes';
import Academy from './pages/Academy';
import Promotions from './pages/Promotions';

function App() {
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 992);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 992);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        <Router>
            <ScrollToTop />
            <AnnouncementBar />
            {
                isMobile ? <MobileHeader /> : <Header />
            }
            {/* <Header /> */}
            <Routes>
                <Route path='/' element={<Homepage />} />
                <Route path='/about' element={<About />} />
                <Route path='/trading-platforms' element={<TradingPlatforms />} />
                <Route path='/investment-products' element={<InvestmentProducts />} />
                <Route path='/advantages' element={<Advantages />} />
                <Route path='/account-types' element={<AccountTypes />} />
                <Route path='/academy' element={<Academy />} />
                <Route path='/promotions' element={<Promotions />} />
                <Route path='/news-feed' element={<NewsFeed />} />
                <Route path='/faq' element={<Faq />} />
                {/* <Route path='/campaigns' element={<Campaigns />} /> */}
                <Route path='/register' element={<Register />} />
            </Routes>
            <Footer />
        </Router>
    );
}

export default App;
