import React from 'react'
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';
import { useTranslation } from "react-i18next";

const SubpageBanner = ({ title, image }) => {
    const { t } = useTranslation();
    return (
        <div className="subpage-about-container" style={{ backgroundImage: `url(${image})` }}>
            <div className="subpage-about-content">
                <h1>{title}</h1>
                <p>
                    <Link className='text-white text-decoration-none' to="/">{t("homepage")}</Link> / {title}
                </p>
            </div>
        </div>
    )
}

export default SubpageBanner