import React from 'react'
import SubpageBanner from '../components/SubpageBanner'
import AccountTypesImg from "../assets/img/account-types/hesap-turleri.png"
import AccountTypesComponent from '../components/AccountTypesComponent'
import { useTranslation } from 'react-i18next'

const AccountTypes = () => {
    const { t } = useTranslation();
    return (
        <div className='account-types-content'>
            <SubpageBanner title={t("accountTypesTitle")} image={AccountTypesImg} />
            <div className="container">
                <AccountTypesComponent />
            </div>
        </div>
    )
}

export default AccountTypes